import React, { useState, useEffect } from 'react';
import Loader from '../../../components/loader';
import "../fees.css";
import api from '../../../api/api';
import {numToCurrency} from "../../../utils/currency"
import noData from "../../../assets/fees/no-data.svg"

function Payment_history(props) {
    const {fee_receipt_option}=props
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [selectedPayments, setSelectedPayments] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     if (props.inst_acad_year_id) {
    //       downloadReceipt(props.inst_acad_year_id);
    //     }
    //   }, [props.inst_acad_year_id]);
      
    useEffect(() => {
        if (props?.fetchStudent?.result?.p_install_li) {
            setPaymentHistory(props.fetchStudent.result.p_install_li);
        }
    }, [props?.fetchStudent]);


    const handleSelectPayment = (receiptNo) => {
        if (selectedPayments.includes(receiptNo)) {
            setSelectedPayments(selectedPayments.filter(id => id !== receiptNo));
        } else {
            setSelectedPayments([...selectedPayments, receiptNo]);
        }
    };

   
    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedPayments([]);
        } else {
            setSelectedPayments(paymentHistory.map(item => item.f_rec_no)); 
        }
        setSelectAll(!selectAll);
    };

    const downloadReceipt = async (receiptNo, finYr, instAcadYearId) => {
        setLoading(true);
        let request = {
            url: `/api/v1/studentWise/fee/${props.student_id}/feeReceipt/${receiptNo}/download?fin_yr=${finYr}&acad_year=${instAcadYearId}`,
            token: props.student_auth,
        };

        try {
            const res = await api.getAuth(request);
            if (res.data && res.data.awsDownloadLink) {
                downloadFile(res?.data?.awsDownloadLink)
            }
        } catch (error) {
            console.error("Failed to download receipt", error);
        } finally {
            setLoading(false);
        }
    };

    const downloadFile = (url) => {
        try {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", ""); 
           
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            
        } catch (error) {
            console.error("Download failed:", error);
        }
    };
    
    

    return (
        <>
                {!props.fetchStudent && (
                <div>
                    <Loader />
                </div>
            )}
                <div>
                    {console.log("data",props?.fetchStudent)}
                    <div className="d-flex align-items-center card-section">
                        <div className="card crd1">Total Fees: &#8377;{props?.fetchStudent?.result?.total_fee_amount}</div>
                        <div className="card crd2">Paid Fees: &#8377;{props?.fetchStudent?.result?.total_paid_amount}</div>
                        <div className="card crd3">Due Fees: &#8377;{props?.fetchStudent?.result?.total_unpaid_amount}</div>
                    </div>
                    
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    {/* <th><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th> */}
                                    <th>Instl. No.</th>
                                    <th>Date</th>
                                    <th>{props.is_institute_type_school ? "Standard" : "Batch"}</th>
                                    <th>Mode</th>
                                    <th>Receipt No.</th>
                                    <th>Paid Amount</th>
                                    <th>Balance Amount</th>
                                  
                                    {fee_receipt_option && (
              <th>Download Receipt</th>
            )}

                                </tr>
                            </thead>
                            <tbody>
                                {paymentHistory.length > 0 ? (
                                    paymentHistory.map((item, index) => (
                                        <tr key={index}>
                                            {/* <td>
                                                <input 
                                                    type="checkbox" 
                                                    checked={selectedPayments.includes(item.f_rec_no)}
                                                    onChange={() => handleSelectPayment(item.f_rec_no)}
                                                />
                                            </td> */}
                                            {console.log("fee receipt",fee_receipt_option)}
                                            <td>{item.install_nos || "N/A"}</td>
                                            <td>{item.p_date || "N/A"}</td>
                                            <td>
                                                {props.is_institute_type_school 
                                                    ? item.stnd_n || "-" 
                                                    : item.c_n || "-"}
                                                </td>
                                            <td>{item.p_method || "N/A"}</td>
                                            <td>{item.f_rec_no || "N/A"}</td>
                                            <td>{numToCurrency(item.p_amount ?? 0)}</td>
                                            <td>{numToCurrency(item.d_amount ?? 0)}</td>
                                            <td>
                                            {fee_receipt_option && (
                                                <button 
                                                onClick={() => downloadReceipt(item.f_rec_no, item.financial_yr, props.inst_acad_year_id)}
                                                className="blue_button"
                                                >
                                                Download
                                                </button>
                                            )}
                                            </td>

                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                    <td colSpan="8">
                                        <div className="no-data-container">
                                            <p className="up-text2">No Data Available</p>
                                            <img src={noData} alt="No Data" className="no-data-img"/>
                                        </div>
                                    </td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            
        </>
    );
}

export default Payment_history;
