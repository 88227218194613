import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../components/header";
import api from "../../api/api";
import Payment_history from "./paymentHistory/paymentHistory";
import Due_details from "./dueDetails/dueDetail";
import filter from "../../assets/header/filter.png";

function Fees(props) {
  const [activeTab, setActiveTab] = useState("due");
  const [feesData, setFeesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [localSelectedYear, setLocalSelectedYear] = useState(null);

  const globalSelectedYear = props.auth.selectedAcadYear;  
  console.log("auth",props.auth)
  console.log(props.auth.inst_set_up.student_fee_receipt_option
  )
  console.log("log",props.auth.student_acad_year[0]?.acad_yr_name)
  useEffect(() => {
    if (props.auth.acad_year_list.length > 0) {
      let defaultYear;

      if (props.auth.is_institute_type_school && globalSelectedYear) {
        defaultYear = props.auth.acad_year_list.find(
          (year) => year.inst_acad_year_id === globalSelectedYear.inst_acad_year_id
        );
      } 
      if(props.auth.student_acad_year[0]?.acad_yr_name)  {
        console.log("inside")
        defaultYear = props.auth.acad_year_list.find(
          (year) => year.inst_acad_year === props.auth.student_acad_year[0]?.acad_yr_name
        );
      }
      
      if (!defaultYear) {
        console.log("in")
        defaultYear = props.auth.acad_year_list[props.auth.acad_year_list.length - 1];
      }
console.log("def",defaultYear)
      setLocalSelectedYear(defaultYear);
    }
  }, [props.auth.acad_year_list, globalSelectedYear, props.auth.is_institute_type_school]);

  useEffect(() => {
    if (localSelectedYear) {
      fetchStudentFees(localSelectedYear.inst_acad_year_id);
    }
  }, [localSelectedYear]);

  const fetchStudentFees = (inst_acad_year_id) => {
    setLoading(true);
    let request = {
      url: `/api/v1/studentWise/fee/${props.auth.institute_id}/students/${props.auth.student_id}/${inst_acad_year_id}`,
      token: props.auth.student_auth,
    };

    api
      .getAuth(request)
      .then((res) => {
        setFeesData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching fees:", error);
      });
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="fees-header">
          <div className="nav-item" style={{ marginLeft: "32px", display: "flex", alignItems: "center" }}>
            <button className={`nav-link ${activeTab === "due" ? "active" : "inactive"}`} onClick={() => setActiveTab("due")}>
              Due Details
            </button>
          </div>
          <div className="nav-item" style={{ marginLeft: "32px", display: "flex", alignItems: "center" }}>
            <button className={`nav-link ${activeTab === "payment" ? "active" : "inactive"}`} onClick={() => setActiveTab("payment")}>
              Payment History
            </button>
          </div>


          {props.auth.acad_year_list.length > 0 && (
            <div className="header_doc-filter-dropdown-fee">
              <img src={filter} height={15} width={15} />
              <select
                name="academic year"
                onChange={(e) => setLocalSelectedYear(JSON.parse(e.target.value))}  
                value={JSON.stringify(localSelectedYear)}
              >
                {props.auth.acad_year_list.map((item, ind) => (
                  <option key={ind} value={JSON.stringify(item)}>
                    {item.inst_acad_year}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>


        <div className="tab-content mt-3">
          {activeTab === "due" && <Due_details 
          fetchStudent={feesData}
          is_institute_type_school={props.auth.is_institute_type_school} 
          />}
          {activeTab === "payment" && <Payment_history 
          fetchStudent={feesData} 
          inst_acad_year_id={localSelectedYear?.inst_acad_year_id} 
          student_id={props.auth.student_id}
          student_auth={props.auth.student_auth}
          is_institute_type_school={props.auth.is_institute_type_school}
          fee_receipt_option={props.auth.inst_set_up.student_fee_receipt_option}
          />}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Fees);
