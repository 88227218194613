import React, { useState, useEffect } from 'react';
import "../fees.css";
import Loader from '../../../components/loader';
import {numToCurrency} from "../../../utils/currency"
import noData from "../../../assets/fees/no-data.svg"

function Due_details(props) {
    const [installments, setInstallments] = useState([]);
    const [selectedInstallments, setSelectedInstallments] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    // console.log("props",props.fetchStudent)
    useEffect(() => {
        if (props?.fetchStudent?.result?.a_install_li) {
            setInstallments(props?.fetchStudent?.result?.a_install_li);
        }
    }, [props?.fetchStudent]);

    const getNumberOfDays = (date1, date2) => {
        const diffTime = new Date(date1) - new Date(date2);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    };

    const getFeeStatus = (item) => {
        if (item.p_status === 'N' && item.p_amount === 0 &&  item.bad_debt_amount === 0 && getNumberOfDays(new Date(), item.d_date) > 1) {
            return 'Overdue';
        } else if (item.p_status === 'N' && item.p_amount === 0 && item.bad_debt_amount === 0) {
            return 'Due';
        } else if (Number(item.initial_amount) + Number(item.tax_amount) - Number(item.disc_amount) === 0) {
            return 'Discounted';
        } else if (item.p_status === 'Y') {
            return 'Paid';
        } else if (item.p_status === 'N' && item.bad_debt_amount != 0){
            return 'Bad Debt'
        }
        else {
            return 'Partially Paid';
        }
    };

    
    // const handleCheckboxChange = (installmentId) => {
    //     setSelectedInstallments((prevSelected) => {
    //         if (prevSelected.includes(installmentId)) {
    //             return prevSelected.filter((id) => id !== installmentId);
    //         } else {
    //             return [...prevSelected, installmentId];
    //         }
    //     });
    // };

    
    // const handleSelectAll = () => {
    //     if (selectAll) {
    //         setSelectedInstallments([]);
    //     } else {
    //         const allInstallmentIds = installments.map((inst) => inst.f_schld_id);
    //         setSelectedInstallments(allInstallmentIds);
    //     }
    //     setSelectAll(!selectAll);
    // };

    return (
        <>
            {!props.fetchStudent && (
                <div>
                    <Loader />
                </div>
            )}
            <div>
                <div id="due-dtl" className="tab-content">
                    <div className="d-flex align-items-center card-section">
                        <div className="card crd1">Total Fees: &#8377;{props?.fetchStudent?.result?.total_fee_amount}</div>
                        <div className="card crd2">Paid Fees: &#8377;{props?.fetchStudent?.result?.total_paid_amount}</div>
                        <div className="card crd3">Due Fees: &#8377;{props?.fetchStudent?.result?.total_unpaid_amount}</div>
                    </div>
                    <div className='table-container'>
                        <table>
                            <thead>
                                <tr>
                                    {/* <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                        />
                                    </th> */}
                                    <th>Instl. No.</th>
                                    <th>Date</th>
                                    <th>{props.is_institute_type_school ? "Standard" : "Batch"}</th>
                                    <th>Total Amount</th>
                                    <th>Fine Amount</th>
                                    <th>Balance Amount</th>
                                    <th>Status</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {installments.length > 0 &&
                                installments.filter(
                                    (item) => getFeeStatus(item) !== "Paid" &&
                                            getFeeStatus(item) !== "Bad Debt" &&
                                            (item.d_amount + parseInt(item?.balance_fine ?? 0)) > 0 
                                ).length > 0 ? (
                                    installments
                                    .filter(
                                        (item) => getFeeStatus(item) !== "Paid" &&
                                                getFeeStatus(item) !== "Bad Debt" &&
                                                (item.d_amount + parseInt(item?.balance_fine ?? 0)) > 0 
                                    )
                                    .map((item, index) => (
                                        <tr key={index}>
                                            {/* <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedInstallments.includes(item.f_schld_id)}
                                                    onChange={() => handleCheckboxChange(item.f_schld_id)}
                                                />
                                            </td> */}
                                            <td>{item.install_no}</td>
                                            <td>{item.d_date || "N/A"}</td>
                                            <td>
                                                    {props.is_institute_type_school 
                                                        ? item.stnd_n || "-" 
                                                        : item.c_n || "-"}
                                                    </td>
                                            <td>{numToCurrency(item.t_amount ?? 0)}</td>
                                            <td>{numToCurrency(item.balance_fine ?? 0)}</td>
                                            <td>{numToCurrency(item.d_amount + parseInt(item?.balance_fine ?? 0))}</td>
                                            <td>{getFeeStatus(item)}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                    <td colSpan="7">
                                        <div className="no-data-container">
                                            <p className="up-text2">No Data Available</p>
                                            <img src={noData} alt="No Data" className="no-data-img"/>
                                        </div>
                                    </td>
                                </tr>
                                
                                )}
                            </tbody>


                        </table>
                       
                    </div>
                    
                </div>
            </div>
         
            {/* <div className="button-container">
                <button className="blue_button" disabled={selectedInstallments.length === 0}>
                    Proceed to Pay
                </button>
            </div> */}

        </>
    );
}

export default Due_details;
